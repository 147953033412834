



* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    
    background-color: #f8f8f8;
    font-family: Arial, sans-serif;
  }

  h1, h2, h3, p {
      color: #01665e;
  }

  a, .cta-button {
    color: #FFB6C1;
  }
  
  .logo {
    
    position: absolute; /* Position the logo absolutely within the panel*/
    top: 3rem; /*Adjust as necessary for padding from the top of the panel*/
    left: 3rem;  /*Adjust as necessary for padding from the left of the panel*/
    width: 100px; /*Adjust as necessary for the size of the logo*/
    height: 100px; /*Adjust as necessary for the size of the logo*/
    transform: scale(1.5); /* Adjust the scaling factor as needed */
    
  }
  
  
  .container {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    gap: 2rem; 
  }
  
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: 2.5rem;
  }

  .panel {
    height: 80vh; 
    border: 2px dotted #a8e0b7;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
  }
  
  .panel-green {
    background-color:white;
    position: relative;
  }
  
  .panel-white {
    background: #c9f4e4;
  }

  .call-to-action {
    grid-column: span 2;
    background-color:#a8e0b7;
    border: 2px dotted #a8e0b7;
    color: white;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }

  .call-to-action-link {
    grid-column: span 2;
  }

  .call-to-action:hover {
    transform: scale(1.02);
}
  
  .call-to-action a {
    color: #01665e;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .infographic-stat-circle {
    width: 240px;
    height: 240px;
    border-radius: 50%;  /* Make the div circle-shaped */
    background-color:white;
    display: flex;
    flex-direction: column;  /* Change the direction to column */
    justify-content: center; /* Center the text horizontally */
    align-items: center; /* Center the text vertically */
    margin: 10px; /* Add some space between the circles */
    font-size: 20px;
    font-weight: bold;
    padding: 20px;  /* Add some padding */
    box-sizing: border-box;  /* Include padding and border in element's total width and height */
}

.infographic-circle-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.infographic-circle-number {
    color: #01665e;
    font-size: 48px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.infographic-circle-comment {
    font-size: 17px;
    color: #01665e;
    text-align: center;
}