.number-input-container {
    position: relative;
    width: 400px;
    margin: 0 auto;
    height: 62.5px;
}
  
.number-input {
    background-color: #c9f4e4;
    color: black;
    width: 100%;
    height: 38.5px;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 4px;
    position: relative;
    z-index: 1;
}
  
.number-input:focus {
    outline: none;
    background-color: #a8e8d4;
}
  
.number-input-label {
    display: block;
    margin-bottom: 5px;
    color: black;
    text-align: center;
    z-index: 2;
    pointer-events: none;
}

.dual-number-input {
    width: calc(50% - 35px); /* This gives each input half the space minus half the gap */
}

.dual-number-input + .dual-number-input {
    margin-left: 70px; /* This creates the 10px gap between the two inputs */
}



