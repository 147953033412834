nav {
    background-color: #f8f8f8; /* You can set the color of the navbar */
    position: fixed;  /* Navbar fixed at the top of the page */
    width: 100%;      /* Full width */
    top: 0;           /* Stick to the top */
    left: 0;          /* Stick to the left */
    padding: 15px;    /* Padding inside navbar */
    
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);  /* Shadow for depth perception */
    z-index: 100; 
    display: flex;
    justify-content: space-between;
   /* Ensure the navbar stays on top of other elements */
}

nav ul {
    display: flex;    /* Horizontally align the list items */
    justify-content: flex-end; /* Align to the right side */
    list-style: none; /* Remove default list styles */
    margin: 0;        /* No margin */
    padding-right: 0px; /* Add right padding */
}

.nav-left {
    justify-content: flex-start;
    padding-left: 0px; /* Additional padding for pushing content to the left */

}

.nav-right {
    justify-content: flex-end;
    padding-right: 20px;
}

nav ul li {
    margin-left: 20px; /* Space between list items */
}

nav ul li a, nav ul li button {
    text-decoration: none; /* Remove default link styles */
    color: #333; /* Link color, adjust as needed */
    font-weight: bold; /* Bold text for links */
}

nav ul li button {
    margin-top:0;
    background: none; /* No background for the button */
    border: none; /* No border for the button */
    padding: 0; /* No padding for the button */
    cursor: pointer; /* Pointer cursor for the button */
}

nav ul li:last-child button {
    margin-left: 20px; /* Space between the button and the previous list item */
    display: inline-block; /* Ensure the button is displayed inline with the list items */
  }
  

nav ul li button:hover {
    color: #888; /* Slightly lighter color when hovering */
}
