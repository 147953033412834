.vaccinations-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  min-height: 99vh;  /* changed from height to min-height */
  margin-left: 10%;
  overflow: auto;
  z-index: 1;
}


.vaccinations-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  min-height: 200px;
}

.vaccinations-header h3 {
  margin-top: 20px;
}

.vaccination-card {
  width: 45%; /* Adjust this value to control the card width */
  height: 220px; /* Adjust this value to control the card height */
  display: flex;
  border: 15px solid #d6d6d6;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;

}

.vaccination-card.active {
  background: #d6d6d6;
  transform: scale(1.02);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.vaccination-card:hover {
  background-color: #d6d6d6;  /* Or any lighter shade of grey you prefer */
}

.vaccination-card-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #01665e;
  margin-top: 20px;
}



.vaccination-count {
  color: #01665e;
  font-size: 48px;
  margin-bottom: 0px; 
  margin-top: 0px;/* Add some space below the number */
}



.vaccination-comment {
  font-size: 14px; /* Decrease the comment font size */
  color: #01665e;
  text-align: center;
}

.vaccinations-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  width: 100%;
  padding: 20px;
  height: auto;
  flex-grow: 1;
  overflow: auto;
}


.vaccinations-details-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.vaccinations-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto; /* Allow vertical scroll */
  max-height: calc(100% - 50px); /* Deduct the space occupied by header */
  position: relative;
}

.vaccination-detail {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.vaccination-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.vaccination-description {
  font-size: 1em;
  text-align: center;
}
