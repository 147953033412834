.screenings-and-vaccinations-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-height: 99vh;
  margin-left: 5%;
  overflow: auto;
  z-index: 1;
}

.screenings-and-vaccinations-header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  min-height: 200px;
}



.screenings-and-vaccinations-header-wrapper {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto; /* this will create 4 rows, adjusting their height based on content */
  gap: 20px;
  padding:20px;
  width: 80%;
  row-gap:5px;
  height: 100%;
  justify-items: center; /* This will horizontally center each grid item */
  align-items: center; /* This will vertically center each grid item */
   /* Adjust this as per the space you want between this row and the top row */
}



.screenings-and-vaccinations-header h3 {
  margin-top: 20px;
}

.screenings-and-vaccinations-card {
  width: 100%;
  display: flex;
  border: 10px solid #d6d6d6;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.screenings-and-vaccinations-card.active {
  background: #d6d6d6;
  transform: scale(1.02);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  z-index: 2;
}


.vaccination-screening-button{
  margin-top: 20px;
  width: 80%;
}

.vaccination-screening-button:hover {
  background-color: #d6d6d6;
}

.vaccination-screening-button.selected-feature {
  background-color: #e0e0e0; /* a slightly darker shade for the selected disease */
  font-weight: bold; /* make the text bold to highlight it's selected */
}

.screenings-and-vaccinations-card-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #01665e;
  margin-top: 20px;
}

/*
.screenings-and-vaccinations-count {
  color: #01665e;
  font-size: 48px;
  margin-bottom: 0px; 
  margin-top: 0px;
}*/

.screenings-and-vaccinations-comment {
  font-size: 14px; 
  color: #01665e;
  text-align: center;
}

.screenings-and-vaccinations-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  width: 100%;
  padding: 20px;
  height: auto;
  flex-grow: 1;
  overflow: auto;
}

.screenings-and-vaccinations-details-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.screenings-and-vaccinations-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto; 
  max-height: calc(100% - 50px);
  position: relative;
}

.screenings-and-vaccinations-detail {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.screenings-and-vaccinations-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.screenings-and-vaccinations-description {
  font-size: 1em;
  text-align: center;
}


.disease-cards-wrapper {
  display: flex;
    /* or adjust as needed to get the desired vertical starting position */
  justify-content: space-between; /* evenly distribute the space between the cards */
  flex-wrap: wrap; /* allow items to wrap to the next row if needed */
  gap: 20px;
  justify-content: flex-start; /* starts items at the top */

}

.disease-card {
  /* your existing .disease-card styles */
  flex: 1; /* allow the cards to grow and occupy the available space */
  margin: 0 5px; /* add a small margin to the left and right of each card */
  border: 10px solid #d6d6d6; /* Matches the screenings-and-vaccinations-card border */
  border-radius: 8px; /* Optional: gives a rounded corner look */
  box-sizing: border-box; /* Makes sure padding and border are included in the element's total width and height */
  padding: 10px;
  
}

/* 3. Center the text in each card */
.disease-card h3,
.disease-card h4,
.disease-card p,
.disease-card ul {
  text-align: center;
}

/* 4. Style list items */
.disease-card ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Removes default padding */
  display: grid; /* Make this a grid container */
  grid-template-columns: repeat(2, 1fr); /* Two columns, each taking up half the available space */
  gap: 10px; /* Spacing between grid items */
}

.disease-card li {
  cursor: pointer; /* Indicates that the item is clickable */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  padding: 5px; /* Adds a bit of space inside each list item */
  border-radius: 5px; /* Optional: gives a rounded corner look */
  text-align: center; /* Center the text inside the li */
  border: 1px solid #d6d6d6; /* Border for the li, can be modified or removed */
}

.disease-card li:hover {
  background-color: #d6d6d6; /* Background color when item is hovered */
}

.disease-card ul li.selected-disease {
  background-color: #e0e0e0; /* a slightly darker shade for the selected disease */
  font-weight: bold; /* make the text bold to highlight it's selected */
}
