body {
    background-color: white;
}

.container {
    width: 90%; /* Increase width of section */
    padding-top: 4rem;
    margin: auto;
    text-align: center;
    flex-direction: column;
}



.about-us h1, .our-story h1, .team h1 {
    font-size: 2.5rem;
    color: #01665e;
}

.about-us h2, .our-story h2, .team h2 {
    font-size: 2rem;
    color: #01665e;
    font-weight: bold;
}

.about-us p {
    font-size: 1.3rem;
}

p {
    color: black;
}

.about-us {
    max-width: 50%;
    margin: auto; /* Add this to center the about us section */
    margin-top: 5rem;
    text-align: center; /* Add this to center the text */
}

.our-story {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.story-content {
    display: flex;
}

.story-content img {
    max-width: 60%; /* Increase the width of the image */
    margin-right: 2rem; /* Add a gap between image and text */
}

.story-text {
    text-align: left;
}

.our-story blockquote {
    font-style: italic;
}

.team {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: start;
    margin-top: 5rem;
}

.team h1 {
    grid-column: span 2;
}

.team-member {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 3rem;
}

.team-member-top {
    display: flex;
    align-items: flex-start;
}

.team-member-info {
    margin-left: 2rem;
    text-align: left;
}

.team-member img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 2rem;
}

.team-member-info a {
    color: #01665e;
}
