.health-risk-recommendations-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    min-height: 99vh;
    margin-left: 10%;
    overflow: auto;
    z-index: 1;
  }
  
  .health-risk-recommendations-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
    min-height: 200px;
    flex-wrap: wrap; /* Allow cards to wrap to new line when there are many */
  }
  
  .health-risk-recommendation-card {
    flex: 1; /* Allow cards to take up as much space as available */
    min-width: 160px; /* Minimum width for a card */
    max-width: 1000px;;
    height: 260px;
    display: flex;
    border: 15px solid #d6d6d6;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    margin: 10px;

  }
  
  .health-risk-recommendation-card.active {
    background: #d6d6d6;
    transform: scale(1.02);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  .health-risk-recommendation-card:hover {
    background-color: #d6d6d6;  /* Or any lighter shade of grey you prefer */
  }
  
  .health-risk-recommendation-card-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #01665e;
    margin-top: 20px;
  }
  
  .health-risk-recommendation-count {
    color: #01665e;
    font-size: 48px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  .health-risk-recommendation-comment {
    font-size: 14px;
    color: #01665e;
    text-align: center;
  }
  
  .health-risk-recommendations-details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    width: 100%;
    padding: 20px;
    height: auto;
    flex-grow: 1;
    overflow: auto;
  }
  
  .health-risk-recommendations-details-header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .health-risk-recommendations-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-y: auto; /* Allow vertical scroll */
    max-height: calc(100% - 50px); /* Deduct the space occupied by header */
    position: relative;
  }
  
  .health-risk-recommendation-detail {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .health-risk-recommendation-disease-title {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }
  
  .health-risk-recommendation-value {
    font-size: 1em;
    text-align: center;
  }
  