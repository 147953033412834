body {
    background-color: white;
}

.privacy-container {
    width: 80%;
    padding-top: 4rem;
    margin: auto;
}

.intro {
    text-align: center; /* Center the text for the intro */
}

.privacy-container h1 {
    font-size: 2.5rem;
    color: #01665e;
}

.privacy-container h2,
.privacy-container p,
.privacy-container ul {
    text-align: left; /* Left-align the text for the rest of the content */
}

.intro p {
    text-align: center; /* Center the text for the intro */
}

.privacy-container h2 {
    font-size: 2rem;
    color: #01665e;
    font-weight: bold;
}

.privacy-container p
 {
    font-size: 1.3rem;
    color: black;
}


.privacy-container a {
    font-size: 1.3rem;
    color: #01665e;
}
.privacy-container ul {
    list-style-type: disc;
    padding-left: 2rem;
    font-size: 1.3rem;
    color: black;
}
