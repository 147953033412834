.places-input-container {
    position: relative;
    width: 400px;
    margin: 0 auto;
    height: 62.5px;
    
    
}
  
.places-input {
    background-color: #c9f4e4;
    color: black;
    width: 100%;
    height: 38.5px;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 4px;
    position: relative;
    z-index: 1;
}
  
.places-input:focus {
    outline: none;
    background-color: #a8e8d4;
}
  
.places-input-label {
    display: block;
    margin-bottom: 5px;
    color: black;
    text-align: center;
    z-index: 2;
    pointer-events: none;
}

.css-13cymwt-control{
    background-color: #c9f4e4 !important;

}



.css-1nmdiq5-menu {
    z-index: 9999 !important;; /* or any high number */
    
  }
  .address-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* This targets all input placeholders */
.form-control::placeholder {
    font-family: Arial, sans-serif;
    color: black; /* Optional: You can adjust the color if you want */
    opacity: 1; /* Ensures that the placeholder is fully opaque */
}

/* This is for cross-browser compatibility */
.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-family: Arial, sans-serif;
}
.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-family: Arial, sans-serif;
    opacity: 1;
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-family: Arial, sans-serif;
    opacity: 1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: Arial, sans-serif;
}