body {
    background-color: white;
}

.register-form {
    width: 400px;
    margin: auto;
    margin-top: 80px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.register-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.register-form input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.register-form button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.register-form button:hover {
    background-color: #0056b3;
}

.register-form .error-message {
    color: red;
    margin-bottom: 10px;
}

.login-section {
    text-align: center;
    margin-top: 20px;
}

.login-section button {
    padding: 10px;
    border: none;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.login-section button:hover {
    background-color: #1e7e34;
}

.terms-section {
    display: flex;
    align-items: center;
  }
  
  .terms-section input[type="checkbox"] {
    flex: 0; /* This will prevent the checkbox from growing and taking up extra space */
  }
  
  .terms-section label {
    flex: 1; /* This will allow the text to grow and take up the remaining space */
    margin-left: 10px;
  }
  