.dropdown {
    background-color: #c9f4e4;
    color: black;
    width: 400px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  
  .dropdown.closed {
    max-height: 70px;
  }
  
  .dropdown:not(.closed) {
    max-height: 530px; /* Adjust this value to your needs */
  }
  
  .dropdown-button {
    padding: 10px;
    cursor: pointer;
    height: auto;
    white-space: normal;
  }
  .dropdown-input-label {
    display: block;
    margin-bottom: 5px;
    color: black;
    text-align: center;
    z-index: 2;
    pointer-events: none;
}
  
  

  .dropdown .dropdown-button:hover {
    background-color: #a8e8d4; /* This color is a slightly darker shade of the original background color */
    cursor: pointer;
  }

  .dropdown li:hover {
    background-color: #a8e8d4;
    cursor: pointer;
  }
  
  

  
  .dropdown-arrow {
    float: right;
    margin-left: -30px;
    margin-right: 5px;
  }
  
  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown li {
    display: flex;
    align-items: center;
    justify-content: center; /* This will horizontally center the content */
    width: 100%;
}

.dropdown li label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* This will horizontally center the content */

}

.dropdown li input.multi-checkbox {
    margin-right: 10px;
}

  


  