.user-menu h2 {
    margin-top:70px;
}

a {
    text-decoration: none;
}



.user-menu {
    position: fixed; 
    top: 0;
    left: 0;
    width: 10%; 
    height: 100vh; 
    padding: 0 1rem;
    background-color: #eaf9e5;
    color: #ffffff;
    overflow-y: auto;
    transition: left 0.3s ease;
  }

.user-menu-button-section  {
    display: flex;
    margin-top:70px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 90%; 
}

.user-menu-logo {
    width: 50px;
    height: auto;
    margin: 10px 0;
    transform: scale(3); 
}

.user-menu-logo:hover {
    background-color: #a8e6cf;
}

.user-menu h2 {
    text-align: center;
}

