body {
    margin:0;
    position: relative;
    background-color: #f8f8f8;
}

.welcome-message h1 {
    margin-top:60px;
    padding: 0;
}

.welcome-message p {
    margin-top:-15px;
    padding: 0;
}


a {
    text-decoration: none;
}

  
  .dashboard-container {
    margin-left: 0%;
    width: 100%;
    height: 99vh;
    overflow-y: auto;
    transition: left 0.3s ease;
  }

.content-area {
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-right: 30px;
}

.welcome-message {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 0px;
}

/* The rest of the CSS file remains the same until */

.main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .wrapper-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 20px;  /* Adjust this to the gap you want between the two wrappers */
}
  
  .live-well-section {
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 2
  }
  
  .live-well-section-wrapper {
    background-color: white;
    display: grid;
    border: 1px solid rgba(0,0,0,0.1);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto; /* this will create 4 rows, adjusting their height based on content */
    gap: 20px;
    padding:20px;
    width: 100%;
    row-gap:5px;
    height: 100%;
    justify-items: center; /* This will horizontally center each grid item */
    align-items: center; /* This will vertically center each grid item */
     /* Adjust this as per the space you want between this row and the top row */
  }
  
  
  
  
  
  .goals-section,
.button-section,
.dashboard-stat-card {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  

  
  .goals-header {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .goals-section p {
      margin-top:0px;   
  }

  .goals-section:hover{
    transform: scale(1.02);
  }

  

  

/*  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
*/

/*
  .dashboard-card-title {
    font-size: 22px; 
    font-weight: bold;
    text-align: center;
    color: #01665e;
    margin-bottom: 10px;
    text-decoration: none !important;

  }
  .dashboard-stat-card {
    width: 70%; 
    height: 240px;
    display: flex;
    border: 15px  solid #d6d6d6;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;  
    box-sizing: border-box;  
  }

  .dashboard-stat-card:hover {
    transform: scale(1.02);
    }

.dashboard-card-number {
    color: #01665e;
    font-size: 48px;
    margin-bottom: 10px; 
}

.dashboard-card-comment {
    font-size: 14px; /
    color: #01665e;
    text-align: center;
}

.card-green {
    background-color: white;
}

.card-red {
    background-color: white;
}
 
*/


.stay-well-section {
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 55px;
    flex: 1;
}

.stay-well-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.stay-well-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.live-well-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stay-well-screening-logo {
    width: 50px;
    height: auto;
    transform: scale(5); 
    margin-top: 20px;
}
/*
.stay-well-vaccination-logo {
    width: 50px;
    height: auto;
    transform: scale(7); 
    margin-top: 20px;
}
*/
.live-well-health-plan-logo {
    width: 50px;
    height: auto;
    transform: scale(5); 
    margin-top: 0px;
}

/*
.stay-well-card {
    width: 150px;
    height: 120px;
    display: flex;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: #c9f4e4;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;  
    box-sizing: border-box;  
}

.stay-well-card-title {
    font-size: 18px; 
    font-weight: bold;
    text-align: center;
    color: #01665e;
    margin-bottom: 5px;
}

.stay-well-card-number {
    color: #01665e;
    font-size: 32px;
    margin-bottom: 5px;
}

.stay-well-card-comment {
    font-size: 12px;
    color: #01665e;
    text-align: center;
}
*/


.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap: 20px;*/
    text-align: center; /* This centers the text */
}



/* Add to existing CSS */
.section h2 {
    text-decoration: none !important;
    margin-top: 20px; /* Adjust this to create more or less space between the logo and the comment */
}

.section h3 {
    margin-top: 15px; /* Adjust this to create more or less space between the logo and the comment */
}

.section h3 + button {
  margin-top: 15px; /* Adjust this value to your preference */
}

/* Increase the space between the h2 and the following logo container in the section */
.section h2 + .stay-well-logo-container {
  margin-top: 20px; /* Adjust this value to your preference */
}

.section-title {
  grid-column: 1 / -1; /* this will make it span both columns */
  text-align: center; /* center the text */
  
}

.health-plan-text p {
  color: #01665e;
  margin: 20px 0;  /* adjust as needed */
  text-align: center;       /* Left-align the bullet points */
}

.stay-well-section ul {
  list-style-type: none; /* removes bullets */
  padding-left: 0;
  text-align: center; /* centers list items */
}

.stay-well-section li {
  color: #01665e;
  font-size: large;
  margin: 20px 10px; /* add space between inline list items, adjust as needed */
}


.health-plan-button {
  margin-top: 0;
}