body {
    background-color: lightgray;
  }
  
  .input-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    margin-top: 110px;  
    background-color: white;
    
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }


  .input-container form > div {
    margin-top: 60px !important;
  }
  
  

  
  .title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 3rem;
  }
  
  .subtitle {
    text-align: center;
    font-size: 1rem;
    color: gray;
    margin-bottom: 2rem;
    margin: 2rem;
  }
  
  .input-group {
    margin-bottom: 45px;
    size: 100px !important;
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center child elements horizontally */
    justify-content: center; 
  }
  
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 60px;
    color: black;
    background-color: #b7d7e8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #95c3e0;
  }

 
  .label-flex {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .label-flex > button {
    margin-left: 10px;
  }
  
  .toggle-unit {
    color: #007BFF; /* A blue color like typical hyperlinks */
    cursor: pointer;
    text-decoration: underline; /* Underline to make it apparent they're clickable */
    margin: 0 5px; /* Some space between them */
}

.toggle-unit:hover {
    color: 
    #0056b3; /* A darker blue for hover effect */
}

.toggle-unit-active {
  font-weight: bold;
  color: #0056b3; /* A darker blue to indicate it's active */
}

