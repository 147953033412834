.feature-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999; 

  }
  
  .feature-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    min-height: 200px;
    max-width: 550px;
    width: 90%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
  }
  
  .feature-modal-content p {
    margin-bottom: 15px;
  }
  
  .feature-modal-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .feature-modal-content button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
  }
  
  .feature-modal-content button:active {
    background-color: #45a049;
  }
  
  .feature-modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px; /* Define the width */
    height: 25px; /* Define the height */
    font-size: 1em;
    background: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    display: flex; /* Add these to center the 'X' */
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Add this to make it circular */
  }
  
  .feature-modal-textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    margin: 15px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .feature-modal-submit {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
  }
  
  .feature-modal-submit:active {
    background-color: #45a049;
  }