body {
    background-color: white;
  }
  
  .login-form {
    width: 400px;
    margin:  auto;
    margin-top: 80px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-form button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  
  .login-form .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .signup-section {
    text-align: center;
    margin-top: 20px;
  }
  
  .signup-section button {
    padding: 10px;
    border: none;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .signup-section button:hover {
    background-color: #1e7e34;
  }
  