body {
    background-color: white;
}

.container {
    width: 90%; /* Increase width of section */
    padding-top: 4rem;
    margin: auto;
    text-align: center;
    flex-direction: column;
}

.our-services h1 {
    font-size: 2.5rem;
    color: #01665e;
}

.our-services {
    font-size: 2rem;
    color: #01665e;
    font-weight: bold;
}

.our-services p {
    font-size: 1.3rem;
}

p {
    color: black;
}

.our-services {
    max-width: 50%;
    margin: auto; /* Center the about us section */
    margin-top: 5rem;
    text-align: center; /* Center the text */
}

.our-services-section {
    margin-top: 5rem;
    display: flex;
    justify-content: center; /* Center the items horizontally */
    flex-wrap: wrap; /* Wrap items onto the next line */
    align-items: flex-start; /* Align items to the top */
}


.our-services-content {
    display: flex;
    flex-direction: row;
    align-items: stretch; /* this will ensure the children of this div take full height */
    margin-bottom: 4rem; /* Space between the items when they wrap */
    max-height: 500px;
     /* set max-height to your desired value */
}

  
  .our-services-content img {
    object-fit: cover; /* this will ensure the image covers the full div without distortion */
    margin-right: 5rem; /* Add a gap between image and text */
    flex: 1; /* this will ensure the image container takes half the space */
    max-height: 500px; /* this will ensure the image covers full height of the container */
    width: 500px; /* this will maintain the aspect ratio of the image */
    transform: scale(1); 
    

  }
  
  .our-services-text {
    text-align: left;
    max-width: 600px;
    flex: 1; /* this ensures the text container takes the remaining half space */
  }
  
  .our-services-text ul {
    list-style-type: disc; /* This is the default value for unordered lists */
    padding-left: 1.1rem; /* This adds some space to the left of the bullet points */
  }
  
  .our-services-text ul li {
    font-size: 1.3rem;
    color: black;
    margin-bottom: 1rem; /* This adds some space between the list items */
  }