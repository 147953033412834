/* DateInput.css */

.date-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.MuiPickersDay-dayWithMargin {
    margin: 0 2px;
    color: inherit;
}

/* Add this in your global CSS or styled component */
.MuiPickersArrowSwitcher-iconButton {
    color: black !important; 
}

.MuiPickersArrowSwitcher-root {
    justify-content: space-between !important;  /* Adjust the positioning of children */
    margin-right: 30px;
  }


.date-picker-wrapper {
    width: 400px;
    height: 38.5px;
    overflow: visible; /* This line makes sure that any overflowing content will be visible */
}

.MuiPickersModal-dialog {
    width: 350px !important; /* adjust this value as per your requirement */
  }

.date-input-label {
    margin-bottom: 5px;
    text-align: center;
}

.date-input {
    background-color: #c9f4e4;
    color: black;
    width: 100%;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 4px;
    line-height: 1.5; 
    font-size: 1em;
    transition: background-color 0.3s ease;
}
  
.date-input:focus {
    outline: none;
    background-color: #a8e8d4;
}
